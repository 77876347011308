import { ApiClientCredentials } from "src/components/ApiClients/ApiClientCredentials"
import { ApiClientForm } from "src/components/ApiClients/ApiClientForm"
import {
  useFetchApiClient,
  useFetchApiClientSecret,
  usePatchApiClient,
} from "src/data/apiClients/apiClientQueries"
import {
  IApiClient,
  IApiClientPostBody,
} from "src/data/apiClients/apiClientTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MDialog } from "src/ui/Dialog/MDialog"

export function EditApiClientDialog({
  clientId,
}: {
  clientId: IApiClient["client_id"]
}) {
  const { t, langKeys } = useTranslate()
  const { goBack } = useRouter()
  const patchApiClient = usePatchApiClient()
  const fetchApiClientSecret = useFetchApiClientSecret({ clientId })
  const fetchApiClient = useFetchApiClient({
    clientId,
  })

  const apiClient = fetchApiClient.data
  const clientSecret = fetchApiClientSecret.data?.client_secret

  function handleSubmit(body: IApiClientPostBody) {
    const { owner, ...patchBody } = body
    patchApiClient.mutate(
      { clientId, body: patchBody },
      {
        onSuccess: () => {
          handleClose()
        },
      }
    )
  }

  function handleClose() {
    goBack({ defaultPath: Routes.ApiClientsSetting.location() })
  }

  if (!apiClient || !clientSecret) {
    return null
  }

  // remove properties from IApiClient that is not being used in IApiClientPostBody
  const { client_id, created_at, deleted, ...postBody } = apiClient

  const formId = "edit-api-client-form"

  return (
    <MDialog
      title={t(langKeys.api_clients_edit_title)}
      onClose={handleClose}
      confirmLabel={t(langKeys.save)}
      formId={formId}
      open={true}
      loading={patchApiClient.isLoading}
      error={patchApiClient.isError && t(langKeys.failed_general_error_title)}
    >
      <ApiClientCredentials clientId={clientId} clientSecret={clientSecret} />

      <ApiClientForm
        formId={formId}
        initialApiClient={postBody}
        onSubmit={handleSubmit}
      />
    </MDialog>
  )
}
