import { useState } from "react"
import styled from "styled-components"

import { IconButton, TextField } from "@material-ui/core"

import { IApiClientPostBody } from "src/data/apiClients/apiClientTypes"
import { OwnerType } from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { AddButton } from "src/ui/Button/AddButton"
import TrashSvg from "src/ui/icons/trash.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { StrictPropertyCheck } from "src/utils/tsUtil"

export function ApiClientForm<T extends IApiClientPostBody>({
  formId,
  initialApiClient,
  onSubmit,
}: {
  formId: string
  initialApiClient?: T &
    StrictPropertyCheck<
      T,
      IApiClientPostBody,
      "Only allowed properties of IApiClientPostBody"
    >
  onSubmit: (formData: IApiClientPostBody) => void
}) {
  const organization = useOrganization()
  const { t, langKeys } = useTranslate()

  // Initialize api client, if new client, initialize empty client with sane defaults, else use existing
  const _initialApiClient: IApiClientPostBody = initialApiClient ?? {
    name: "",
    owner: {
      type: OwnerType.ORGANIZATION,
      id: organization.orgId,
    },
    authorization_code_grant: {
      require_pkce: true,
      redirect_uris: [""],
    },
    client_credentials_grant: {
      enabled: true,
    },
  }

  const [formData, setFormData] = useState(_initialApiClient)

  function updateFormData(newFormData: Partial<IApiClientPostBody>) {
    setFormData((data) => ({ ...data, ...newFormData }))
  }

  function handleSubmit() {
    onSubmit(formData)
  }

  const codeGrant = formData?.authorization_code_grant
  const redirectUris = codeGrant?.redirect_uris

  return (
    <Form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <Row>
        <MText variant="subtitle">{t(langKeys.api_clients_form_name)}</MText>

        <TextField
          required
          fullWidth
          value={formData.name}
          onChange={(event) => updateFormData({ name: event.target.value })}
        />
      </Row>

      <Row>
        <MText variant="subtitle">{t(langKeys.api_clients_redirect_uri)}</MText>

        {redirectUris?.map((redirectUri, index, array) => (
          <TextField
            value={redirectUri}
            required
            key={`redirect-uri-${index}`}
            onChange={(event) => {
              const _redirectUris = structuredClone(redirectUris)
              _redirectUris[index] = event.target.value

              updateFormData({
                authorization_code_grant: {
                  ...codeGrant,
                  redirect_uris: _redirectUris,
                },
              })
            }}
            InputProps={{
              endAdornment:
                array.length === 1 ? undefined : (
                  <IconButton
                    onClick={() => {
                      updateFormData({
                        authorization_code_grant: {
                          ...codeGrant,
                          redirect_uris: redirectUris.filter(
                            (_, i) => index !== i
                          ),
                        },
                      })
                    }}
                  >
                    <TrashSvg />
                  </IconButton>
                ),
            }}
          />
        ))}

        <div>
          <AddButton
            variant="minimal"
            onClick={() =>
              updateFormData({
                authorization_code_grant: {
                  ...codeGrant,
                  redirect_uris: [...redirectUris, ""],
                },
              })
            }
          >
            {t(langKeys.api_clients_form_add_another_uri)}
          </AddButton>
        </div>
      </Row>
    </Form>
  )
}

const Form = styled.form`
  display: grid;
  gap: ${spacing.L};
`

const Row = styled.div`
  display: grid;
  gap: ${spacing.XS};
`
