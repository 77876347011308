import styled from "styled-components"

import { OrganizationDelete } from "src/components/Organizations/OrganizationEdit/OrganizationDelete"
import { SensorSwap } from "src/components/Organizations/OrganizationEdit/SensorSwap"
import { EditField } from "src/components/Settings/EditField"
import { API_DEFAULT } from "src/constants/minutApi"
import { EditType, ITextInput } from "src/data/editField/editFieldTypes"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { Feature } from "src/data/featureAvailability/types/featureAvailabilityTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePatchOrganization } from "src/data/organizations/queries/organizationQueries"
import { IOrganization } from "src/data/organizations/types/organizationTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useTranslate } from "src/i18n/useTranslate"
import { DebugBox } from "src/ui/Debug/DebugBox"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function OrganizationSettings() {
  const { t, langKeys } = useTranslate()

  const { org } = useOrganization()
  const orgId = org.id

  const user = useGetUser()

  const sensorSwapAvailability = useFeatureAvailability({
    feature: Feature.SENSOR_SWAP,
  })
  const showSensorSwapToggle =
    sensorSwapAvailability.ready && sensorSwapAvailability.available

  const patchOrganization = usePatchOrganization()

  const { hasOwnerAccess: isOwner, hasAdminAccess: isAdmin } = getAccessLogic({
    role: org?.user_role,
  })
  const editText = isAdmin
    ? undefined
    : t(langKeys.organizations_only_editable_by_owner)

  const OrgName: ITextInput<IOrganization, Partial<IOrganization>> = {
    title: `${t(langKeys.organizations_organization_name)}`,
    info: `${t(langKeys.organizations_change_organization_name)}`,
    maxLength: 50,
    type: EditType.INPUT_TEXT,
    id: "org.name",
    defaultValue: "",
    storedValue: (org: IOrganization): string => {
      return org?.name || ""
    },
    payload: (name: string) => ({ name }),
    endpointUrl: (org: IOrganization) =>
      `${API_DEFAULT}/organizations/${org.id}`,
  }

  return (
    <Box>
      <MText variant="heading2">
        {t(langKeys.organizations_organization_settings)}
      </MText>

      <EditField
        fieldData={{ ...OrgName, editText, disabled: !isAdmin }}
        storedSettings={[org]}
        submit={(val, c) =>
          patchOrganization.mutateAsync({
            orgId,
            body: { name: String(val) },
          })
        }
        loading={patchOrganization.isLoading}
      />

      {showSensorSwapToggle && (
        <SensorSwap orgId={orgId} enabled={!!org.sensor_swap?.enabled} />
      )}

      <OrganizationDelete id={orgId} name={org.name} canDeleteOrg={isOwner} />

      <DebugBox
        title={"orgQuery.data"}
        data={{ ...org, guest_communication: "hidden" }}
      />
      <DebugBox title={"user"} data={user} hidden />
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  grid-gap: ${spacing.M};
  max-width: 600px;
  margin-top: ${spacing.XL};
`
